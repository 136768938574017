'use client';

import { redirect } from 'next/navigation';

import { useLocalisation } from '@lib/machine-parts/storefront/utils';

export default function Error() {
    const { language, locale } = useLocalisation();
    redirect(`/${language}/${locale}/error`);
}
